import React, { Component } from 'react';
import { Row, Col, Button, Image, Container } from 'react-bootstrap';
import cat from '../images/cat.gif';

class NotFound extends Component {
    render() {
        return (
            <Container id="body" className="page">
                <Row>
                    <Col md className="pb-3 d-flex align-items-center justify-content-center">
                        <Image src={cat} alt='Cat Gif' rounded fluid />
                    </Col>
                    <Col md className="text-center">
                        <h1>Page Not Found</h1>
                        <Button className='btn' variant="outline-light" href="/">
                            Return to Home Page
                        </Button>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default NotFound;
