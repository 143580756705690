const username = "StephenDavisIO";

export const FiveHundredPX = () => "https://500px.com/p/" + username + "/";

export const LinkedIn = () => "https://www.linkedin.com/in/" + username + "/";

export const Instagram = () => "https://www.instagram.com/" + username + "/";

export const GitLab = () => "https://gitlab.com/StephenDavis/";

export const Twitter = () => "https://twitter.com/" + username + "/";
