import React, { Component } from 'react';
import { Row } from 'react-bootstrap';
import Layout from '../components/Layout';

class About extends Component {
    render() {
        return (
            <Layout>
                <Row className="text-center">
                    <figure>
                        <blockquote className="blockquote">
                            <strong>It’s fine to celebrate success, but it is more important to heed the lessons of failure.</strong>
                        </blockquote>
                    <figcaption className="blockquote-footer">
                        <strong>Bill Gates</strong>
                    </figcaption>
                    </figure>
                </Row>
                <Row>
                    <h2>A Bit About Me</h2>
                    <p>
                        Welcome to my website and thank you for taking the time to explore
                        it. Allow me to share a glimpse of my journey with you. As someone
                        who witnessed the evolution of technology from its nascent stages,
                        including the birth of the internet and the strides in video games
                        and PC hardware, and the dawn of artificial intelligence, I readily
                        identify myself as a fervent technology enthusiast.
                    </p>

                    <p>
                        During the formative summers of my youth, my introduction to programming
                        occurred through my enrolment in the Real Programming 4 Kids camp, a pivotal
                        experience. Here, I delved into the world of game development, crafting my
                        first creation – Donkey Kong – using Visual Basic .NET. This initiation into
                        the realm of computer programming ignited a lifelong passion within me.
                    </p>

                    <p>
                        A decade onwards, I attained an Ontario Diploma in Protection, Security &
                        Investigations from Humber College. My trajectory led me to join the Toronto
                        Police Service as a civilian service member, aiming to pursue a career in law
                        enforcement. However, after nearly two years, driven by various factors, I
                        recognized that my true calling lay elsewhere. The burgeoning domain of
                        cryptocurrency seized my interest, inspiring me to delve into Ethereum mining,
                        rekindling my enthusiasm for computer science.
                    </p>

                    <p>
                        Coinciding with my Ethereum mining endeavours, I embarked on a Computer Science
                        bachelor’s program at Lakehead University, a partnership with Georgian College,
                        situated in Barrie, Ontario. Following my first year, life presented a profound
                        juncture as my grandparents necessitated my care. I temporarily withdrew from
                        Lakehead University to provide support, an intermission marked by the passing of
                        my grandmother. Following a period of reflection, I embarked on the Computer
                        Programming Analyst, Advanced Diploma program, at George Brown College. Anchored
                        in Toronto, this choice enabled me to tend to my grandfather while pursuing my
                        studies, culminating in a robust foundation for software development.
                    </p>

                    <p>
                        Intrigued by the dynamics of web applications and fortified by my George Brown
                        College experience, my curiosity impelled me to augment my expertise. Hence, I
                        enrolled in the eCornell Web App Development certificate program offered by Cornell
                        University. This intensive undertaking immerses me in the comprehensive spectrum of
                        full stack development, encompassing front-end design finesse, back-end optimization,
                        adept data manipulation via databases, and adept application flow governance.
                    </p>

                    <p>
                        Through my odyssey of life experiences, one truth became evident: my destiny lies in
                        software engineering. The dynamic challenges intrinsic to this field hold immense
                        gratification, as they afford me the opportunity to tangibly enhance the lives of others.
                        Just as the internet is an elemental facet of the global technological framework, I am
                        convinced that it should be regarded as an inherent human entitlement. It's this belief
                        that propels me toward a career dedicated to full stack development. Aligned with my
                        conviction, the diploma program at George Brown College meticulously delves into web
                        application development, encompassing a panorama of technologies and concepts pivotal
                        to full stack prowess.
                    </p>

                    <p>
                        Embedded in my core philosophy is the perpetual pursuit of learning and personal growth.
                        As a testament to this belief, I am presently pursuing a bachelor’s degree in computing
                        science through Thompson Rivers University, Open Learning. This online program empowers me
                        to balance full-time work with part-time learning, fostering a continuous journey of
                        knowledge acquisition.
                    </p>

                    <p>
                        In this juncture, fortified by a George Brown College education in web applications, I opt
                        to augment my acumen further through the eCornell Web App Development certificate from Cornell
                        University. As the realm of full stack development captures my focus, the immersive program
                        equips me with the acumen to orchestrate holistic digital solutions. Thus, my journey is
                        guided by an unwavering dedication for software engineering, a driving force that transforms
                        challenges into rewarding pursuits, knowing that every endeavour has the potential to enrich
                        the lives of others.
                    </p>
                </Row>
            </Layout>
        )
    }
}

export default About;