import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './components/Styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as Pages from './routes';
  
const root = createRoot(document.getElementById('root'));
root.render(
    <Router>
        <Pages.NavigationBar />
        <Routes>
            <Route path="/" element={<Pages.Home />} />
            <Route path="/about/" element={<Pages.About />} />
            <Route path="/portfolio/" element={<Pages.Portfolio />} />
            <Route path="*" element={<Pages.NotFound />} />
        </Routes>
    </Router>
);
