import Logo from './Logo';
import { Navbar, Container, Nav, NavLink } from 'react-bootstrap';

function NavigationBar() {
    return (
        <Navbar fixed="top" bg="dark" variant="dark" expand="lg">
            <Container id="header">
                <Navbar.Brand href="/">
                    <Logo />
                    Stephen Davis
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbar-nav" />
                <Navbar.Collapse className="justify-content-end" id="navbar-nav">
                    <Nav className="ml-auto">
                        <NavLink href="/about/">About Me</NavLink>
                        <NavLink href="/portfolio/">Portfolio</NavLink>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavigationBar;