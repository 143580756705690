import * as React from "react"

const ExternalLink = ({ title, url}) => {
    return (
        <a href={url} target="_blank" rel="noreferrer" className="link">
          {title}
        </a>
    )
};

export default ExternalLink;