import * as React from "react";
import { Container, Navbar, Nav, Row } from "react-bootstrap";
import {
    SiInstagram,
    Si500Px,
    SiLinkedin,
    SiGitlab,
    SiTwitter
  } from "react-icons/si";
import ExternalNavLink from "../helpers/ExternalNavLink";
import * as Data from '../components/Data';

const iconSize = "1.5em";
function SiteFooter() {
    return (
        <Navbar variant="dark" className="pt-3">
            <Container className="justify-content-md-center" id="footer">
                <Row className="mx-auto pt-0">
                    <Nav className="justify-content-between">
                        <ExternalNavLink title={<SiInstagram title="Instagram Profile" size={iconSize} />} url={Data.Instagram()} />
                        <ExternalNavLink title={<Si500Px title="500px Photo Album" size={iconSize} />} url={Data.FiveHundredPX()} />
                        <ExternalNavLink title={<SiLinkedin title="LinkedIn Profile" size={iconSize} />} url={Data.LinkedIn()} />
                        <ExternalNavLink title={<SiGitlab title="GitLab Profile" size={iconSize} />} url={Data.GitLab()} />
                        <ExternalNavLink title={<SiTwitter title="Twitter Profile" size={iconSize} />} url={Data.Twitter()} />
                    </Nav>
                </Row>
            </Container>
        </Navbar>
    )
}

export default SiteFooter;
