import * as React from 'react';
import { Container } from 'react-bootstrap';
import SiteFooter from './SiteFooter';

//className="justify-content-md-center"
export default function Layout({ children }) {
    return (
        <>
            <Container id="body" className="page">
                {children}
            </Container>
            <SiteFooter />
        </>
    )
}