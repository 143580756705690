import React, { Component } from 'react';
import { Row } from 'react-bootstrap';
import Layout from '../components/Layout';

class Portfolio extends Component {
    render() {
        return (
            <Layout>
                <Row>
                    <h2>Coming Soon!</h2>
                </Row>
            </Layout>
        )
    }
}

export default Portfolio;
