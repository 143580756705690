import * as React from "react";
import { Nav } from "react-bootstrap";

const ExternalNavLink = ({ title, url }) => {
    return (
        <Nav.Link target="_blank" rel="noreferrer" href={url}>
            {title}
        </Nav.Link>
    )
};

export default ExternalNavLink;